import { Injectable } from '@angular/core'

import jsonexport from 'jsonexport/dist'
import { saveAs } from 'file-saver'

@Injectable()
export class CsvService {
  download(data, name) {
    jsonexport(data, (err, csv) => {
      if (err) {
        throw err
      }

      const blob = new Blob([csv], { type: 'text/csv' })
      saveAs(blob, name)
    })
  }
}
