'use strict';

module.exports.isFunction = function (fn) {
  var getType = {};
  return fn && getType.toString.call(fn) === '[object Function]';
};
module.exports.isArray = function (arr) {
  return Array.isArray(arr);
};
module.exports.isObject = function (obj) {
  return obj instanceof Object;
};
module.exports.isString = function (str) {
  return typeof str === 'string';
};
module.exports.isNumber = function (num) {
  return typeof num === 'number';
};
module.exports.isBoolean = function (bool) {
  return typeof bool === 'boolean';
};
module.exports.isDate = function (date) {
  return date instanceof Date;
};