/* jshint node:true */
'use strict';

/**
 * Module dependencies.
 */
//const _ = require('underscore');
var _typeof = typeof Symbol === "function" && typeof Symbol.iterator === "symbol" ? function (obj) {
  return typeof obj;
} : function (obj) {
  return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj;
};
var Parser = require('./parser/csv');
var Stream = require('./core/stream');
var helper = require('./core/helper');
var EOL = require('./core/eol');

/**
 * Main function that converts json to csv
 *
 * @param {Object|Array} json
 * @param {Object} [options]
 * @param {Function} callback(err, csv) - Callback function
 *      if error, returning error in call back.
 *      if csv is created successfully, returning csv output to callback.
 */
module.exports = function () {
  var DEFAULT_OPTIONS = {
    headers: [],
    //              Array
    rename: [],
    //               Array
    headerPathString: '.',
    //    String
    rowDelimiter: ',',
    //        String
    textDelimiter: '"',
    //       String
    arrayPathString: ';',
    //     String
    undefinedString: '',
    //      String
    endOfLine: EOL || '\n',
    //   String
    mainPathItem: null,
    //       String
    booleanTrueString: null,
    //  String
    booleanFalseString: null,
    // String
    includeHeaders: true,
    //     Boolean
    fillGaps: false,
    //          Boolean
    verticalOutput: true,
    //     Boolean
    forceTextDelimiter: false //Boolean
  };
  // argument parsing
  var json = void 0,
    userOptions = void 0,
    callback = void 0;
  if (arguments.length === 3) {
    var _arguments = Array.prototype.slice.call(arguments);
    json = _arguments[0];
    userOptions = _arguments[1];
    callback = _arguments[2];
  } else if (arguments.length === 2) {
    var any = void 0;
    var _arguments2 = Array.prototype.slice.call(arguments);
    json = _arguments2[0];
    any = _arguments2[1];
    if (typeof any === 'function') {
      callback = any;
    } else if ((typeof any === 'undefined' ? 'undefined' : _typeof(any)) === 'object') {
      userOptions = any;
    }
  } else if (arguments.length === 1) {
    var _arguments3 = Array.prototype.slice.call(arguments),
      _any = _arguments3[0];
    if ((typeof _any === 'undefined' ? 'undefined' : _typeof(_any)) === 'object') {
      var defaultKeys = Object.keys(DEFAULT_OPTIONS);
      var objectKeys = Object.keys(_any);
      var isOptions = objectKeys.every(function (key) {
        return defaultKeys.includes(key);
      });
      if (objectKeys.length > 0 && isOptions) {
        userOptions = _any;
      } else {
        json = _any;
      }
    } else {
      json = _any;
    }
  } else {
    return new Stream(new Parser(DEFAULT_OPTIONS));
  }
  var options = Object.assign({}, DEFAULT_OPTIONS, userOptions);
  var parser = new Parser(options);
  // if no json is provided Stream API will be used
  if (!json) {
    return new Stream(parser);
  }
  // always return an promise
  return new Promise(function (resolve, reject) {
    parser.parse(json, function (err, result) {
      if (callback) return callback(err, result);
      if (err) return reject(err);
      if (reject) return resolve(result);
    });
  });
};